import { useEffect, useState, useCallback } from "react"
import { formatEuros } from "../utils/finance"
import { createOrder, getCurrentBalanceCents, getCurrentUser, getProducts } from "../services/apiClient"
import Spinner from "../components/Spinner";
import { useParams, useLocation, NavLink, useOutletContext } from 'react-router-dom';
import Alert from "./Alert";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
// Import the easter egg hooks
import { useConfettiEasterEgg } from "../components/Confetti";
import { useDiscoModeEasterEgg } from "../components/DiscoMode";
import { useNegativeBalanceAnimation } from "../components/NegativeBalanceAnimation";

const OrderSelector = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [isOrdering, setIsOrdering] = useState(false)
    const [error, setError] = useState(null)
    const [orderLines, setOrderLines] = useState([])
    const [products, setProducts] = useState([])
    const [grandTotal, setGrandTotal] = useState(0)
    const [currentBalanceCents, setCurrentBalanceCents] = useState(0)
    const [successMessage, setSuccessMessage] = useState(null)
    const [animatingProductId, setAnimatingProductId] = useState(null)

    // Initialize the confetti easter egg
    const { showConfetti, triggerConfetti, hideConfetti, ConfettiComponent } = useConfettiEasterEgg();

    // Initialize the disco mode easter egg
    const { isDiscoMode, tryTriggerDiscoMode, DiscoModeComponent } = useDiscoModeEasterEgg();

    // Initialize the negative balance animation
    const {
        isAnimating: isNegativeBalanceAnimating,
        checkNegativeBalance,
        startAnimation,
        NegativeBalanceAnimationComponent
    } = useNegativeBalanceAnimation();

    const { id: userId } = useParams();
    const { state } = useLocation();
    const [trigger, setTrigger] = useOutletContext();
    const [user, setUser] = useState(null)

    // Memoize this function to prevent unnecessary recreation
    const calculateGrandTotal = useCallback(() => {
        if (!orderLines.length || !products.length) return 0;

        const total = products.reduce((sum, product) => {
            const orderLine = orderLines.find(line => line.id === product.id);
            const quantity = orderLine ? orderLine.quantity : 0;
            return sum + (product.price * quantity);
        }, 0);

        return total;
    }, [orderLines, products]);

    // Update order line quantity with animation
    const updateQuantity = (productId, change) => {
        // Set animating product ID for visual feedback
        setAnimatingProductId(productId);

        // Clear animation after short delay
        setTimeout(() => {
            setAnimatingProductId(null);
        }, 500);

        setOrderLines(prevLines =>
            prevLines.map(line => {
                if (line.id === productId) {
                    const newQuantity = Math.max(0, line.quantity + change);
                    return { ...line, quantity: newQuantity };
                }
                return line;
            })
        );
    };

    const handleCreateOrder = async () => {
    try {
        // Try to trigger easter eggs
        // First try the confetti easter egg (1 in 20 chance)
        triggerConfetti();

        // Then try the disco mode easter egg (5% chance)
        tryTriggerDiscoMode();

        setIsOrdering(true);
        setError(null);
        setSuccessMessage(null);

        const orderForUserId = userId || (await getCurrentUser()).data.data.id;

        const order = {
            order_for_user: orderForUserId,
            products: orderLines.filter(line => line.quantity > 0)
        };

        const response = await createOrder(order);

        if (response.status) {
            // Calculate the new balance BEFORE updating the order lines or current balance
            const newBalanceValue = currentBalanceCents - grandTotal;

            // IMPORTANT: Check if balance will go negative BEFORE updating state
            // Only check for the current user, not when ordering for someone else
            if (!userId && newBalanceValue < 0) {
                // First start the animation
                startAnimation();
                // Then also call checkNegativeBalance to be safe
                checkNegativeBalance(newBalanceValue);
            }

            // Update the current balance by subtracting the order total
            setCurrentBalanceCents(newBalanceValue);

            // Reset quantities to zero
            setOrderLines(prevLines =>
                prevLines.map(line => ({
                    ...line,
                    quantity: 0
                }))
            );

            setSuccessMessage({
                title: 'Gelukt!',
                message: 'Je bestelling is afgeschreven.'
            });
            setTrigger(prev => !prev);
        } else {
            throw new Error('Order creation failed');
        }
    } catch (err) {
        setError('Bestelling mislukt. Probeer opnieuw.');
    } finally {
        setIsOrdering(false);
    }
};

    // Load initial data
    useEffect(() => {
        const loadInitialData = async () => {
            try {
                setIsLoading(true);

                // Fetch balance
                const balance = await getCurrentBalanceCents();
                setCurrentBalanceCents(balance);

                // Fetch products
                const productsResponse = await getProducts();
                const availableProducts = productsResponse.data.data;
                setProducts(availableProducts);

                // Create order lines - keep existing quantities if present, otherwise set to 0
                setOrderLines(prevLines => {
                    // If we already have order lines, keep them
                    if (prevLines.length > 0) {
                        return availableProducts.map(product => {
                            const existingLine = prevLines.find(line => line.id === product.id);
                            return {
                                id: product.id,
                                quantity: existingLine ? existingLine.quantity : 0
                            };
                        });
                    }
                    // Otherwise create new ones with quantity 0
                    return availableProducts.map(product => ({
                        id: product.id,
                        quantity: 0
                    }));
                });

                // Fetch current user
                const userResponse = await getCurrentUser();
                if (userResponse.status === 200) {
                    setUser(userResponse.data.data);
                }
            } catch (err) {
                setError('Er is een fout opgetreden bij het laden van gegevens.');
            } finally {
                setIsLoading(false);
            }
        };

        loadInitialData();
    }, []);

    // Update grand total when order lines change
    useEffect(() => {
        const total = calculateGrandTotal();
        setGrandTotal(total);
    }, [orderLines, calculateGrandTotal]);

    // Determine if the new balance would be negative
    const newBalance = currentBalanceCents - grandTotal;
    const isNegativeBalance = newBalance < 0;

    // Determine if products are available based on user permissions
    const availableProducts = user ? products.filter(p =>
        !(p.name.toLowerCase().includes('bier') && user.bar_service === 0)
    ) : [];

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-64">
                <div className="pulse-loader">
                    <Spinner />
                </div>
            </div>
        );
    }

    // CSS for animations with added disco mode styles and negative balance animation
    const animationStyles = `
        @keyframes pulse-scale {
            0% { transform: scale(1); }
            50% { transform: scale(1.05); }
            100% { transform: scale(1); }
        }
        
        @keyframes slide-in {
            0% { transform: translateX(20px); opacity: 0; }
            100% { transform: translateX(0); opacity: 1; }
        }
        
        @keyframes bounce {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-10px); }
        }
        
        @keyframes disco-background {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
        }
        
        @keyframes disco-text {
            0% { color: #ff0000; }
            16.6% { color: #ff9900; }
            33.2% { color: #33cc33; }
            49.8% { color: #33ccff; }
            66.4% { color: #9933ff; }
            83% { color: #ff33ff; }
            100% { color: #ff0000; }
        }
        
        @keyframes disco-rotate {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
        
        /* Negative balance animation */
        @keyframes negative-flash {
            0%, 100% { opacity: 0; }
            25%, 75% { opacity: 0.15; }
            50% { opacity: 0.3; }
        }
        
        @keyframes float-up {
            0% { 
                transform: translateY(100%);
                opacity: 0;
            }
            10% {
                opacity: 1;
            }
            90% {
                opacity: 1;
            }
            100% { 
                transform: translateY(-100vh);
                opacity: 0;
            }
        }
        
        .animate-negative-flash {
            animation: negative-flash 1s ease-in-out infinite;
        }
        
        .animate-float-up {
            animation: float-up 3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
        }
        
        .cart-item-enter {
            opacity: 0;
            transform: translateY(20px);
        }
        
        .cart-item-enter-active {
            opacity: 1;
            transform: translateY(0);
            transition: opacity 300ms, transform 300ms;
        }
        
        .cart-item-exit {
            opacity: 1;
        }
        
        .cart-item-exit-active {
            opacity: 0;
            transform: translateY(-20px);
            transition: opacity 300ms, transform 300ms;
        }
        
        .pulse-animation {
            animation: pulse-scale 0.5s ease;
        }
        
        .bounce-animation {
            animation: bounce 0.5s ease;
        }
        
        .bg-gradient-fancy {
            background-image: linear-gradient(135deg, #0061ff 0%, #60efff 100%);
        }
        
        .smooth-shadow {
            box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
        }
        
        .pulse-loader {
            animation: pulse-scale 1.5s infinite ease-in-out;
        }
        
        /* Disco mode styles */
        .disco-text-animation {
            animation: disco-text 2s infinite;
            font-weight: bold;
        }
        
        .disco-rotate-animation {
            animation: disco-rotate 3s infinite linear;
        }
        
        .disco-background {
            background: linear-gradient(90deg, #ff0000, #ff9900, #33cc33, #33ccff, #9933ff, #ff33ff);
            background-size: 600% 600%;
            animation: disco-background 5s ease infinite;
        }
    `;

    return (
        <div className={`max-w-5xl mx-auto px-4 py-6 ${isDiscoMode ? 'transition-all duration-300' : ''}`}>
            <style>{animationStyles}</style>

            {/* Render easter egg and animation components */}
            {ConfettiComponent}
            {DiscoModeComponent}
            {NegativeBalanceAnimationComponent}

            {userId && (
                <NavLink
                    className={`flex items-center bg-white ${isDiscoMode ? 'disco-text-animation' : 'text-blue-700'} hover:bg-blue-50 py-2 px-4 mb-8 rounded-full transition-colors font-medium text-sm shadow-md hover:shadow-lg transition-shadow duration-300`}
                    to="/barservice"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className={`w-5 h-5 mr-1 ${isDiscoMode ? 'disco-rotate-animation' : ''}`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                    Terug
                </NavLink>
            )}

            {/* Header with back navigation */}
            <div className={`${isDiscoMode ? 'disco-background text-white' : 'bg-gradient-fancy text-white'} rounded-2xl smooth-shadow p-6 mb-8 transition-all duration-300`}>
                <div className="flex justify-between items-center">
                    <h1 className={`text-3xl font-bold ${isDiscoMode ? 'disco-text-animation' : ''}`}>
                        Afschrijven {userId ? `voor ${state?.name || 'gebruiker'}` : ''}
                    </h1>
                </div>

                {!userId && (
                    <div className="mt-4 p-4 bg-white bg-opacity-10 backdrop-blur-sm rounded-xl inline-block border border-white border-opacity-20">
                        <h2 className={`text-xl font-semibold flex items-center ${isDiscoMode ? 'disco-text-animation' : ''}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 mr-2 ${isDiscoMode ? 'disco-rotate-animation' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                            </svg>
                            Huidig saldo: <span className={`ml-2 ${currentBalanceCents < 0 ? 'text-red-300 font-bold' : 'text-green-300 font-bold'} ${isDiscoMode ? 'disco-text-animation' : ''}`}>
                                {formatEuros(currentBalanceCents)}
                            </span>
                        </h2>
                    </div>
                )}
            </div>

            {/* Status messages */}
            {error && (
                <Alert
                    title="Bestelling mislukt. Probeer opnieuw."
                    message="Neem contact met ons op als het probleem zich blijft voordoen. Dank je wel!"
                />
            )}

            {successMessage && (
                <Alert
                    title={successMessage.title}
                    message={successMessage.message}
                    color="green"
                />
            )}

            <div className="lg:grid lg:grid-cols-3 lg:gap-6">
                {/* Product selection - Takes 2/3 on large screens */}
                <div className="lg:col-span-2">
                    <div className={`bg-white rounded-2xl shadow-md overflow-hidden mb-6 transition-all duration-300 hover:smooth-shadow ${isDiscoMode ? 'disco-background' : ''}`}>
                        <div className={`px-6 py-4 ${isDiscoMode ? 'bg-opacity-70 text-white' : 'bg-gradient-to-r from-blue-50 to-blue-100 border-b border-blue-200'}`}>
                            <h2 className={`text-xl font-semibold ${isDiscoMode ? 'disco-text-animation' : 'text-blue-800'} flex items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 mr-2 ${isDiscoMode ? 'disco-text-animation' : 'text-blue-600'} ${isDiscoMode ? 'disco-rotate-animation' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                </svg>
                                Selecteer producten
                            </h2>
                        </div>

                        <div className="divide-y divide-gray-100">
                            {availableProducts.map((product) => {
                                const quantity = orderLines.find(o => o.id === product.id)?.quantity || 0;
                                const lineTotal = quantity * product.price;
                                const isAnimating = animatingProductId === product.id;

                                return (
                                    <div
                                        key={product.id}
                                        className={`px-6 py-4 hover:bg-blue-50 transition-colors duration-300 ${quantity > 0 ? 'bg-blue-50' : ''} ${isAnimating ? 'pulse-animation' : ''} ${isDiscoMode ? 'bg-opacity-70 text-white' : ''}`}
                                    >
                                        <div className="flex items-center justify-between">
                                            <div className="flex-1">
                                                <p className={`font-medium ${isDiscoMode ? 'disco-text-animation' : 'text-gray-800'} text-lg`}>{product.name}</p>
                                                <p className={isDiscoMode ? 'disco-text-animation' : 'text-gray-500'}>{formatEuros(product.price)}</p>
                                            </div>

                                            <div className="flex items-center">
                                                <div className={`flex items-center justify-between rounded-full border ${quantity > 0 ? 'border-blue-500 bg-blue-100' : 'border-gray-300'} transition-all duration-300 ${isDiscoMode ? 'border-white' : ''}`}>
                                                    <button
                                                        onClick={() => updateQuantity(product.id, -1)}
                                                        disabled={quantity === 0}
                                                        className={`disabled:opacity-30 w-10 h-10 flex items-center justify-center ${isDiscoMode ? 'disco-text-animation' : 'text-blue-700'} hover:bg-blue-200 rounded-full transition-all duration-200 transform hover:scale-105`}
                                                        aria-label={`Verminder ${product.name}`}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 ${isDiscoMode ? 'disco-rotate-animation' : ''}`} viewBox="0 0 20 20" fill="currentColor">
                                                            <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                                                        </svg>
                                                    </button>

                                                    <span className={`w-10 text-center font-bold ${isDiscoMode ? 'disco-text-animation' : 'text-blue-800'} ${isAnimating ? 'bounce-animation' : ''}`}>
                                                        {quantity}
                                                    </span>

                                                    <button
                                                        onClick={() => updateQuantity(product.id, 1)}
                                                        className={`w-10 h-10 flex items-center justify-center ${isDiscoMode ? 'disco-text-animation' : 'text-blue-700'} hover:bg-blue-200 rounded-full transition-all duration-200 transform hover:scale-105`}
                                                        aria-label={`Vermeerder ${product.name}`}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 ${isDiscoMode ? 'disco-rotate-animation' : ''}`} viewBox="0 0 20 20" fill="currentColor">
                                                            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                                                        </svg>
                                                    </button>
                                                </div>

                                                <div className="ml-6 w-24 text-right">
                                                    {quantity > 0 && (
                                                        <span className={`font-bold ${isDiscoMode ? 'disco-text-animation' : 'text-blue-800'} ${isAnimating ? 'bounce-animation' : ''}`}>
                                                            {formatEuros(lineTotal)}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                {/* Order summary - Takes 1/3 on large screens */}
                <div className="lg:col-span-1">
                    <div className={`bg-white rounded-2xl shadow-md overflow-hidden sticky top-6 transition-all duration-300 hover:smooth-shadow ${isDiscoMode ? 'disco-background' : ''}`}>
                        <div className={`px-6 py-4 ${isDiscoMode ? 'bg-opacity-70 text-white' : 'bg-gradient-to-r from-blue-500 to-blue-600 border-b border-blue-700'}`}>
                            <h2 className={`text-xl font-semibold ${isDiscoMode ? 'disco-text-animation' : 'text-white'} flex items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 mr-2 ${isDiscoMode ? 'disco-rotate-animation' : ''} ${isDiscoMode ? 'disco-text-animation' : 'text-white'}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                                </svg>
                                Je bestelling
                            </h2>
                        </div>

                        <div className="p-6">
                            {/* Display selected items */}
                            <div className="mb-6 min-h-[100px]">
                                <TransitionGroup>
                                    {orderLines
                                        .filter(line => line.quantity > 0)
                                        .map(line => {
                                            const product = products.find(p => p.id === line.id);
                                            if (!product) return null;

                                            return (
                                                <CSSTransition
                                                    key={line.id}
                                                    timeout={300}
                                                    classNames="cart-item"
                                                >
                                                    <div className="flex justify-between py-2 text-gray-700 border-b border-gray-100">
                                                        <span className={isDiscoMode ? 'disco-text-animation' : ''}>{product.name} × {line.quantity}</span>
                                                        <span className={`font-medium ${isDiscoMode ? 'disco-text-animation' : ''}`}>{formatEuros(product.price * line.quantity)}</span>
                                                    </div>
                                                </CSSTransition>
                                            );
                                        })}
                                </TransitionGroup>

                                {orderLines.filter(line => line.quantity > 0).length === 0 && (
                                    <div className="text-center py-6 text-gray-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" className={`h-12 w-12 mx-auto text-gray-300 mb-2 ${isDiscoMode ? 'disco-rotate-animation' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                        </svg>
                                        <p className={isDiscoMode ? 'disco-text-animation' : ''}>Geen producten geselecteerd</p>
                                    </div>
                                )}
                            </div>

                            {/* Order totals */}
                            <div className="border-t border-gray-200 pt-4 space-y-2">
                                <div className="flex justify-between py-2">
                                    <span className={`font-medium ${isDiscoMode ? 'disco-text-animation' : ''}`}>Totaal:</span>
                                    <span className={`font-bold text-lg ${isDiscoMode ? 'disco-text-animation' : ''}`}>{formatEuros(grandTotal)}</span>
                                </div>

                                {!userId && (
                                    <div className="flex justify-between py-2">
                                        <span className={`font-medium ${isDiscoMode ? 'disco-text-animation' : ''}`}>Nieuw saldo:</span>
                                        <span className={`font-bold text-lg ${isNegativeBalance ? 'text-red-600 animate-pulse' : 'text-green-600'} ${isDiscoMode ? 'disco-text-animation' : ''}`}>
                                            {formatEuros(newBalance)}
                                        </span>
                                    </div>
                                )}
                            </div>

                            {/* Submit button */}
                            <button
                                onClick={handleCreateOrder}
                                disabled={grandTotal === 0 || isOrdering}
                                className={`mt-6 w-full disabled:opacity-50 text-white ${isDiscoMode ? 'disco-background' : 'bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800'} focus:ring-4 focus:ring-blue-300 font-medium rounded-lg px-5 py-4 text-center transition-all duration-300 transform hover:scale-[1.02] disabled:hover:scale-100`}
                                aria-busy={isOrdering}
                            >
                                {isOrdering ? (
                                    <div className="flex items-center justify-center">
                                        <Spinner />
                                        <span className="ml-2">Bezig met afschrijven...</span>
                                    </div>
                                ) : (
                                    <div className="flex items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 mr-2 ${isDiscoMode ? 'disco-rotate-animation' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        <span className={isDiscoMode ? 'disco-text-animation' : ''}>Afschrijven</span>
                                    </div>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Random picture section at bottom */}
            <div className="mt-8 bg-white rounded-2xl shadow-md overflow-hidden">
                <RandomPicture isDiscoMode={isDiscoMode} />
            </div>
        </div>
    );
};

const RandomPicture = ({ isDiscoMode }) => {
    const [imageUrl, setImageUrl] = useState("https://www.scouting-alblasserdam.nl/random-image");
    const [isLoading, setIsLoading] = useState(false);

    const refreshImage = () => {
        setIsLoading(true);
        // Add a timestamp to force a new image and avoid cache
        const timestamp = new Date().getTime();
        setImageUrl(`https://www.scouting-alblasserdam.nl/random-image?t=${timestamp}`);
    };

    return (
        <div className="relative">
            {/* Simple rectangular image */}
            <div className="w-full" style={{ paddingBottom: "56.25%" /* 16:9 aspect ratio */ }}>
                <img
                    src={imageUrl}
                    alt="Random foto"
                    className={`absolute top-0 left-0 w-full h-full object-cover ${isLoading ? 'opacity-50' : 'opacity-100'} ${isDiscoMode ? 'animate-pulse' : ''}`}
                    onLoad={() => setIsLoading(false)}
                />
                {isDiscoMode && (
                    <div className="absolute top-0 left-0 w-full h-full disco-background opacity-30"></div>
                )}
            </div>

            {/* Simple refresh button */}
            <button
                onClick={refreshImage}
                className={`absolute bottom-4 right-4 ${isDiscoMode ? 'disco-background text-white' : 'bg-white text-blue-700'} rounded-full p-2 shadow-md`}
                aria-label="Ververs foto"
            >
                <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 ${isDiscoMode ? 'disco-rotate-animation' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
            </button>
        </div>
    );
};

export default OrderSelector;