import {Route, Routes, Navigate, HashRouter} from 'react-router-dom';
import Footer from './components/Footer';
import OrderingPage from './pages/OrderingPage';
import BarService from './pages/BarServicePage';
import IncreasePage from './pages/IncreasePage';
import UserDetailsPage from './pages/UserDetailsPage';
import LoginForm from './components/LoginForm';
import AuthLayout from './layouts/AuthLayout';
import LoginLayout from './layouts/LoginLayout';
import OrderHistoryPage from './pages/OrderHistoryPage';
import RequestResetPasswordForm from './components/RequestResetPasswordForm';
import NewPasswordForm from './components/NewPasswordForm';
import LogoutProcessor from './components/LogoutProcessor';
import PaymentStatusChecker  from './components/PaymentStatusChecker ';

const MainRouter = () => {
    return (
        <div className="min-h-full">
            <HashRouter>
                <Routes>

                    <Route path='/login' element={<LoginLayout />}>
                        <Route path='/login' element={<LoginForm />} />
                        <Route path='/login/resetpassword' element={<RequestResetPasswordForm />} />
                        <Route path='/login/resetpassword/:token' element={<NewPasswordForm />} />
                    </Route>

                    <Route element={<AuthLayout />}>

                        <Route path='/' element={<Navigate to='/login' />} />

                        <Route path='/ordering' element={<OrderingPage />} />
                        <Route path='/barservice' element={<BarService />} />
                        <Route path='/barservice/ordering/:id' element={<OrderingPage />} />
                        <Route path='/increase' element={<IncreasePage />} />
                        <Route path='/increasestatus/:increaseId' element={<PaymentStatusChecker />} />
                        <Route path='/userdetails' element={<UserDetailsPage />} />
                        <Route path='/orderhistory' element={<OrderHistoryPage />} />
                    </Route>
                    <Route path='/logout' element={<LogoutProcessor />} />
                </Routes>
            </HashRouter>

            <Footer />
        </div>
    )
}

export default MainRouter